<template>
  <section class="intro-x">
    <div class="w-full mb-2 flex">
      <div
      title="Ver legendas de estados"
      class="mr-2 cursor-pointer"
      @click="isLegendsVisible = isLegendsVisible ? false : true"
      >
        <svg style="width:24px;height:24px" viewBox="0 0 24 24">
          <path fill="currentColor" d="M12,9A3,3 0 0,1 15,12A3,3 0 0,1 12,15A3,3 0 0,1 9,12A3,3 0 0,1 12,9M12,4.5C16.86,4.5 21.22,7.5 23,12C20.61,18.08 13.75,21.06 7.67,18.67C4.62,17.47 2.2,15.06 1,12C2.78,7.5 7.14,4.5 12,4.5M3.18,12C5.56,16.87 11.45,18.89 16.32,16.5C18.28,15.54 19.86,13.96 20.82,12C18.44,7.13 12.55,5.11 7.68,7.5C5.72,8.46 4.14,10.04 3.18,12M9,22H7V24H9V22M13,22H11V24H13V22M17,22H15V24H17V22Z" />
        </svg>
      </div>
      <h1 class="font-bold text-lg">Estados del pedido</h1>
    </div>
    <OrdersLegends v-if="isLegendsVisible" />
    <div
    class="w-full grid grid-cols-1 md:grid-cols-2 lg:grid-cols-2 xl:grid-cols-4 gap-4 my-3 box p-4"
    >
      <!-- estados -->
      <div class="flex items-center">
        <label for="state" class="w-1/3">Estado</label>
        <select
        v-model="filter.state"
        id="state"
        class="form-control"
        >
          <option value="">--Seleccionar--</option>
          <option
          class="uppercase"
          v-for="(row,k) in getOrdersDomain"
          :key="k"
          :value="k"
          >
            {{row.name}}
          </option>
        </select>
      </div>
      <!-- estados -->
      <div class="flex items-center">
        <label for="state" class="w-1/3">Proveedor</label>
        <div class="p-inputgroup">
          <SelectProveedor
          id="supplierId"
          v-model="filter.supplierId"
          ref="autoCompProvedor" />
        </div>
      </div>
      <!-- botones -->
      <div class="w-full flex items-center ">
        <button
        type="button"
        class="btn btn-primary"
        @click="onFilter"
        >
          Filtrar
        </button>

        <button
        type="button"
        class="btn btn-warning ml-2"
        @click="onFilterClear"
        >
          Limpiar
        </button>
        <router-link
        v-if="$can('pharmasan.administrativa.compras-administrativas.pedidos.access')"
        :to="{name:'pharmasan.administrativa.compras-administrativas.pedidos.formulario'}"
        class="btn btn-primary ml-2">
          Agregar
        </router-link>
        <button
          type="button"
          class="btn btn-success p-1 ml-2"
          title="Actualizar"
          @click="fetchOrders"
          >

            <svg style="width:24px;height:24px" viewBox="0 0 24 24">
              <path fill="currentColor" d="M17.65,6.35C16.2,4.9 14.21,4 12,4A8,8 0 0,0 4,12A8,8 0 0,0 12,20C15.73,20 18.84,17.45 19.73,14H17.65C16.83,16.33 14.61,18 12,18A6,6 0 0,1 6,12A6,6 0 0,1 12,6C13.66,6 15.14,6.69 16.22,7.78L13,11H20V4L17.65,6.35Z" />
            </svg>
          </button>

      </div>
    </div>
    <div class="rounded-sm overflow-hidden">
      <div class="w-full">
        <DataTable @sort="orderFiled" :value="getOrders" class="p-datatable-sm" >
          <Column
          v-if="$can('pharmasan.administrativa.compras-administrativas.pedidos.access')"
          header="Acciones"
          headerStyle="width:170px;"
          bodyClass="flex flex-wrap"
          >
            <template #body="{data}">
              <router-link
              :to="{name: 'pharmasan.administrativa.compras-administrativas.pedidos.detalle', params:{id: data.id}}"
              class="btn btn-primary p-1 m-1"
              title="Ver pedido"
              >
                <svg style="width:24px;height:24px" viewBox="0 0 24 24">
                  <path fill="currentColor" d="M12,9A3,3 0 0,1 15,12A3,3 0 0,1 12,15A3,3 0 0,1 9,12A3,3 0 0,1 12,9M12,4.5C17,4.5 21.27,7.61 23,12C21.27,16.39 17,19.5 12,19.5C7,19.5 2.73,16.39 1,12C2.73,7.61 7,4.5 12,4.5M3.18,12C4.83,15.36 8.24,17.5 12,17.5C15.76,17.5 19.17,15.36 20.82,12C19.17,8.64 15.76,6.5 12,6.5C8.24,6.5 4.83,8.64 3.18,12Z" />
                </svg>
              </router-link>
              <router-link
              :to="{name: 'pharmasan.administrativa.compras-administrativas.pedidos.historial', params: {id: data.id}}"
              class="btn btn-warning m-1 p-1"
              title="Historico"
              >
                <svg style="width:24px;height:24px" viewBox="0 0 24 24">
                    <path fill="currentColor" d="M15,4A8,8 0 0,1 23,12A8,8 0 0,1 15,20A8,8 0 0,1 7,12A8,8 0 0,1 15,4M15,6A6,6 0 0,0 9,12A6,6 0 0,0 15,18A6,6 0 0,0 21,12A6,6 0 0,0 15,6M14,8H15.5V11.78L17.83,14.11L16.77,15.17L14,12.4V8M2,18A1,1 0 0,1 1,17A1,1 0 0,1 2,16H5.83C6.14,16.71 6.54,17.38 7,18H2M3,13A1,1 0 0,1 2,12A1,1 0 0,1 3,11H5.05L5,12L5.05,13H3M4,8A1,1 0 0,1 3,7A1,1 0 0,1 4,6H7C6.54,6.62 6.14,7.29 5.83,8H4Z" />
                </svg>
              </router-link>
              <button
                type="button"
                class="bg-green-600 text-white m-1 py-2 rounded-md btn-sm"
                @click="handleDowloadExcel({orderId: data.id})"
                >
                  <svg style="width:24px;height:24px" viewBox="0 0 24 24">
                    <path fill="currentColor" d="M21.17 3.25Q21.5 3.25 21.76 3.5 22 3.74 22 4.08V19.92Q22 20.26 21.76 20.5 21.5 20.75 21.17 20.75H7.83Q7.5 20.75 7.24 20.5 7 20.26 7 19.92V17H2.83Q2.5 17 2.24 16.76 2 16.5 2 16.17V7.83Q2 7.5 2.24 7.24 2.5 7 2.83 7H7V4.08Q7 3.74 7.24 3.5 7.5 3.25 7.83 3.25M7 13.06L8.18 15.28H9.97L8 12.06L9.93 8.89H8.22L7.13 10.9L7.09 10.96L7.06 11.03Q6.8 10.5 6.5 9.96 6.25 9.43 5.97 8.89H4.16L6.05 12.08L4 15.28H5.78M13.88 19.5V17H8.25V19.5M13.88 15.75V12.63H12V15.75M13.88 11.38V8.25H12V11.38M13.88 7V4.5H8.25V7M20.75 19.5V17H15.13V19.5M20.75 15.75V12.63H15.13V15.75M20.75 11.38V8.25H15.13V11.38M20.75 7V4.5H15.13V7Z" />
                  </svg>
                </button>
                <button
                type="button"
                class="bg-red-500 text-white m-1 py-2 rounded-md btn-sm"
                @click="handleDownloadPDF({id: data.id})"
                >
                  <svg style="width:24px;height:24px" viewBox="0 0 24 24">
                    <path fill="currentColor" d="M19 3H5C3.9 3 3 3.9 3 5V19C3 20.1 3.9 21 5 21H19C20.1 21 21 20.1 21 19V5C21 3.9 20.1 3 19 3M19 5V19H5V5H19M17.9 13.5C17.6 13 16.9 12.8 15.7 12.8C15.3 12.8 14.9 12.8 14.5 12.9C14.2 12.7 13.9 12.5 13.7 12.3C13.1 11.8 12.5 10.9 12.2 9.8V9.7C12.5 8.4 12.8 6.9 12.2 6.2C11.9 6.1 11.7 6 11.5 6H11.3C10.9 6 10.6 6.4 10.5 6.7C10.1 8 10.4 8.7 10.7 9.9C10.5 10.8 10.1 11.7 9.7 12.7C9.3 13.4 9 14 8.7 14.5C8.3 14.7 8 14.8 7.8 15C6.7 15.7 6.1 16.5 6 17V17.5L6.5 17.8C6.7 18 6.8 18 7 18C7.8 18 8.7 17.1 9.9 15H10C11 14.7 12.2 14.5 13.9 14.3C14.9 14.8 16.1 15 16.8 15C17.2 15 17.5 14.9 17.7 14.7C17.9 14.5 18 14.3 18 14.1C18 13.8 18 13.6 17.9 13.5M6.8 17.3C6.8 16.9 7.3 16.3 8 15.7C8.1 15.6 8.3 15.5 8.5 15.4C7.8 16.5 7.2 17.2 6.8 17.3M11.3 6.7C11.3 6.7 11.3 6.6 11.4 6.6H11.5C11.7 6.8 11.7 7.1 11.6 7.7V7.9C11.5 8.1 11.5 8.4 11.4 8.7C11.1 7.8 11.1 7.1 11.3 6.7M10.1 14.3H10C10.1 14 10.3 13.7 10.5 13.3C10.9 12.5 11.3 11.7 11.5 11C11.9 11.9 12.4 12.6 13 13.1C13.1 13.2 13.3 13.3 13.4 13.4C12.5 13.5 11.3 13.8 10.1 14.3M17.3 14.2H17.1C16.7 14.2 16 14 15.3 13.7C15.4 13.6 15.5 13.6 15.5 13.6C16.9 13.6 17.2 13.8 17.3 13.9L17.4 14C17.4 14.2 17.4 14.2 17.3 14.2Z" />
                  </svg>
                </button>
                <button
                v-if="data.correspondenceId"
                type="button"
                class="bg-blue-500 text-white m-1 py-2 rounded-md btn-sm"
                @click="handleCorrespondencia({correspondenceId: data.correspondenceId})"
                >
                  <svg style="width:24px;height:24px" viewBox="0 0 24 24">
                    <path fill="currentColor" d="M8,13H10.55V10H13.45V13H16L12,17L8,13M19.35,10.04C21.95,10.22 24,12.36 24,15A5,5 0 0,1 19,20H6A6,6 0 0,1 0,14C0,10.91 2.34,8.36 5.35,8.04C6.6,5.64 9.11,4 12,4C15.64,4 18.67,6.59 19.35,10.04M19,18A3,3 0 0,0 22,15C22,13.45 20.78,12.14 19.22,12.04L17.69,11.93L17.39,10.43C16.88,7.86 14.62,6 12,6C9.94,6 8.08,7.14 7.13,8.97L6.63,9.92L5.56,10.03C3.53,10.24 2,11.95 2,14A4,4 0 0,0 6,18H19Z" />
                  </svg>
                </button>
              <!-- <router-link
              v-if="data.stateLast === 6"
              :to="{name:'admin.compras.pedidos.form', params:{ id: data.id}}"
              class="btn btn-danger ml-1 p-1"
              title="Duplicar pedido"
              >
                <svg style="width:24px;height:24px" viewBox="0 0 24 24">
                  <path fill="currentColor" d="M11,17H4A2,2 0 0,1 2,15V3A2,2 0 0,1 4,1H16V3H4V15H11V13L15,16L11,19V17M19,21V7H8V13H6V7A2,2 0 0,1 8,5H19A2,2 0 0,1 21,7V21A2,2 0 0,1 19,23H8A2,2 0 0,1 6,21V19H8V21H19Z" />
                </svg>
              </router-link> -->
            </template>
          </Column>
          <Column header="No" field="consecutive" headerStyle="width:140px;" sortable />
          <Column
          header="Fecha"
          field="requestDate"
          headerStyle="width: 160px;"
          sortable
          >
            <template #body="{data}">
              <span>{{$h.formatDate(data.requestDate,'DD/MM/YYYY HH:mm')}}</span>
            </template>
          </Column>
          <Column
          header="Estado"
          field="stateLast"
          headerStyle="width: 240px;"
          sortable
          >
            <template #body="{data}">
              <span class="p-1 rounded-sm" :class="getOrdersDomain[data.stateLast].cssClass">
                {{getOrdersDomain[data.stateLast].name}}
              </span>
            </template>
          </Column>
          <!-- <Column header="Proveedor" field="supplier.cardname" sortable /> -->
          <Column header="Proveedor" field="cardname" sortable />
          <Column header="Num Correspondencia" field="correspondenceId" headerStyle="width:140px;" />
        </DataTable>
      </div>
      <div class="w-full">
        <Paginator
          :rows="getPaginate.perPage"
          :totalRecords="getPaginate.total"
          :first="getPaginate.first"
          :rowsPerPageOptions="[10,20,30]"
          @page="onPage($event)"
        />
      </div>
    </div>
  </section>
</template>

<script>
// import suppliersFindName from '../../../../services/suppliersFindName'
import pedidosListarService from '../../../../services/pedidosListarService'
import { useRoute, useRouter } from 'vue-router'
import axios from 'axios'
import { computed, onMounted, reactive, watch, ref, defineAsyncComponent } from 'vue'
import DataTable from 'primevue/datatable'
import Column from 'primevue/column'
import Paginator from 'primevue/paginator'
import useOrderList from './useOrderList'
import ordersDomain from '../../../../../../../domains/ordersDomain'
import SelectProveedor from '../../../../../../../components/select/proveedores'

export default {
  name: 'listarPedidos',
  components: {
    DataTable,
    Column,
    Paginator,
    OrdersLegends: defineAsyncComponent(() => import('./useLegends')),
    SelectProveedor
  },
  setup () {
    const route = useRoute()
    const router = useRouter()
    const { handleDowloadExcel, handleCorrespondencia } = useOrderList()
    const orders = reactive({
      total: 0,
      per_page: 0,
      data: []
    })
    const isLegendsVisible = ref(false)
    const filter = ref({
      state: '',
      supplierId: null
    })
    const autoCompProvedor = ref(null)
    const getOrdersDomain = computed(() => {
      return ordersDomain
    })
    const getOrders = computed(() => {
      return orders.data
    })
    const getPaginate = computed(() => {
      return {
        perPage: orders.per_page,
        total: orders.total,
        first: +route.query.first || 0
      }
    })
    const onPage = (row) => {
      const page = row.page + 1
      const paginate = row.rows
      const queryParams = { ...route.query }
      const first = row.first
      router.push({ query: { ...queryParams, page, first, paginate } })
    }
    const onFilter = () => {
      const obj = { ...filter.value }
      const queryParams = route.query
      router.push({ query: { ...queryParams, ...obj } })
    }
    const fetchOrders = () => {
      const queryParams = { ...route.query }
      queryParams.page = queryParams.page ? queryParams.page : 1
      queryParams.paginate = queryParams.paginate ? queryParams.paginate : 10
      return pedidosListarService(queryParams).then(({ status, data }) => {
        if (status !== 200) return false
        for (const [key, value] of Object.entries(data)) {
          orders[key] = value
        }
      }).catch(err => {
        console.error(err.message)
      })
    }
    const filterFill = () => {
      const { state, supplierId } = route.query
      filter.value.state = state || ''
      filter.value.supplierId = supplierId || null
    }
    const onFilterClear = () => {
      filter.value.state = ''
      filter.value.supplierId = null
    }
    const orderFiled = ({ sortField, sortOrder }) => {
      const queryParams = route.query
      router.push({ query: { ...queryParams, order: `${sortField},${sortOrder}` } })
    }
    onMounted(() => {
      filterFill()
      fetchOrders()
    })
    watch(() => route.query, (value) => {
      fetchOrders()
    })

    const handleDownloadPDF = (detalle) => {
      const url = `${process.env.VUE_APP_MICROSERVICE_API_SHOPS}/orders-detail-pdf`
      const res = axios.getUri({ url, params: detalle })
      window.open(res, '_blank')
    }

    return {
      filter,
      getOrders,
      getPaginate,
      getOrdersDomain,
      autoCompProvedor,
      isLegendsVisible,
      onPage,
      handleDownloadPDF,
      handleDowloadExcel,
      handleCorrespondencia,
      onFilter,
      onFilterClear,
      orderFiled
    }
  }

}
</script>
<style scoped>
.item{
  height: 15px;
  width: 30px;
  border-radius: 2px;
}
</style>
