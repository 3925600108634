export default {
  1: {
    name: 'Abierto',
    cssClass: [
      'bg-red-300'
    ]
  },
  2: {
    name: 'Solicitado a proveedor',
    cssClass: [
      'bg-blue-500',
      'text-white'
    ]
  },
  3: {
    name: 'Recibido de proveedor',
    cssClass: [
      'bg-yellow-500',
      'text-white'
    ]
  },
  4: {
    name: 'Devuelto a proveedor',
    cssClass: [
      'bg-yellow-900',
      'text-white'
    ]
  },
  5: {
    name: 'Consolidad a inventario',
    cssClass: [
      'bg-green-500',
      'text-white'
    ]
  },
  6: {
    name: 'Anulado',
    cssClass: [
      'bg-indigo-600',
      'text-white'
    ]
  }
}
