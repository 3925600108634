import { messageWarning } from '../../../../../../../libs/mensajes'
import pedidosDescargarExcelService from '../../../../services/pedidosDescargarExcelService'
import configApp from '../../../../../../../config/app'

const useOrderList = () => {
  const handleDowloadExcel = ({ orderId }) => {
    if (!orderId) {
      messageWarning('Es necesario tener el id de la orden para continuar')
      return false
    }
    const url = pedidosDescargarExcelService({ orderId })
    window.open(url, '_blank')
  }

  const handleCorrespondencia = ({ correspondenceId }) => {
    if (!correspondenceId) {
      messageWarning('No existe el numero de correspondencia')
      return false
    }
    const url = `${configApp.intranetOldHost}/corresp/read/${correspondenceId}`
    window.open(url, '_blank')
  }

  return {
    handleDowloadExcel,
    handleCorrespondencia
  }
}

export default useOrderList
